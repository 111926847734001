/**
* Generated automatically at built-time (2024-09-05T09:28:35.174Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-cave-a-vin",templateKey: "sites/76-65cbd7d6-1cb5-4080-9c87-d57257367d18"};